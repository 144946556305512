import React from 'react';

import Title from '../Title';
import RedirectButton from '../RedirectButton';

import style from './pageNotFound.module.css'

const PageNoteFound = () => {
    const mascotteSrc = "/mascotte/BoredMascotte.svg";

    return (
        <div className={style.root}>
            <Title size='1' sticky='false'>
                Oh non ! Pas une erreur 404
            </Title>
            <div className={style.content}>
                <div className={style.messageerror}>
                    {/* <p>Vous venez de trébucher sur un câble.<br />
                    Mais qui laisse traîner ça n'importe où ?</p>
                    <p>Faire une illustration de la mascotte qui se retame par terre</p>
                    <p>Le visage qui frotte par terre. Les jambes en l'air qui se ramene vers le dos.</p>
                    <p>Un cable branché par terre sur la gauche et le cable qui disparait derriere la mascotte</p> */}
                    <p>La ressource que vous demandez n'existe pas.</p>

                    {/* <RedirectButton name='Remettre en ordre' toValue='/' /> */}
                    <RedirectButton name='Accueil' toValue='/' />

                </div>
                <div className={style.mascottepagenotfound}>
                    <img src={mascotteSrc} alt='bored mascotte' />
                </div>
            </div>
        </div>
    );
}

export default PageNoteFound;