import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { getCategories, analytics } from './useHome';

import CategoriesList from '../../components/HomeCategoriesList';

import Title from '../../components/Title';
import Icon from '../../components/Icon';
import LoaderModal from '../../components/LoaderModal';

import style from './home.module.css'

const Home = () => {
    const mascotteSrc = "/mascotte/HiMascotte.svg";

    const [loaderDone, setLoaderDone] = useState(false);

    const [fetchedDataCategories, setFetchedDataCategories] = useState({});
    const [dataCategories, setDataCategories] = useState([]);
    const [catagoriesDataDone, setCatagoriesDataDone] = useState(false);

    // Categories traitment
    const categoriesCheckData = () => {
        if (
            fetchedDataCategories
            && fetchedDataCategories.status
        ) {
            if (!catagoriesDataDone) {
                const categories = fetchedDataCategories.data;

                categories.map((category) => {
                    // set categories enable
                    if (category.category_enable) {
                        setDataCategories(prevDataCategories => [...prevDataCategories, category]);
                    }
                });

                // set category data loaded
                setCatagoriesDataDone(true);
                setLoaderDone(true);
            }
        }
    }

    // fetch categories
    useEffect(() => {
        async function fetchCategories() {
            var categoriesResponse;

            try {
                categoriesResponse = await getCategories();
            } catch (error) {
                console.log(error);
            }
            
            if (categoriesResponse) {
                setFetchedDataCategories(categoriesResponse);
            }
        }
        fetchCategories();
    }, [])

    categoriesCheckData();
    analytics();

    return (
        <div className= {style.root}>
            <Helmet>
                <meta name="og:title" content="Yohannimation" />
                <meta property="og:site_name" content="Yohannimation - Portfolio" />
                <title>Yohannimation</title>
                <meta name="og:description" content="Je suis Yohann RENAULD, développeur web full-stack en alternance et monteur vidéo en autodidacte. Ce site me permet de vous partager mes réalisations." />
                <meta name="description" content="Je suis Yohann RENAULD, développeur web full-stack en alternance et monteur vidéo en autodidacte. Ce site me permet de vous partager mes réalisations." />
                <meta name="og:url" content="https://yohannimation.fr/" />
                <link rel="canonical" href="https://yohannimation.fr/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <LoaderModal done={loaderDone} />
            <Title size='1' sticky='false'>
                Yohann RENAULD - Portfolio
            </Title>
            <p className={style.presentation}>Ce site portfolio me permet de vous partager mes réalisations.<br />Venez découvrir "Yohannimation", mon petit monde regroupant les projets de ces dernières années.</p>
            <div className={style.content}>
                <nav>
                    <ul className={style.navlist}>
                        {catagoriesDataDone ? <CategoriesList categoriesData={dataCategories} /> : ''}
                    </ul>
                </nav>
                <div className={style.mascottehome}>
                    <img src={mascotteSrc} alt='hi mascotte' />
                </div>
            </div>
        </div>
    );
}

export default Home;
