import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo/logo';
import InformationList from './informationList';
import SocialMediaList from './socialMediaList';

import style from './footer.module.css';

const Footer = () => {

    return (
        <footer id="footer" className={style.root}>
            <div className={style.footerlogo}>
                <Link to='/'>
                    <Logo />
                </Link>
            </div>
            <div className={style.footerdata}>
                <InformationList />
                <SocialMediaList />
            </div>
        </footer>
    );
}

export default Footer;