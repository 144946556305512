import React, { useState } from 'react';

import RedirectLink from '../RedirectLink';
import Icon from '../Icon'

import style from './footerList.module.css';

const InformationList = () => {
    const [isCopiedClass, setIsCopiedClass] = useState(style.popup);

    const copyOperation = ()=> {
        navigator.clipboard.writeText('renauldyohann@gmail.com')
            .then(() => {
                setIsCopiedClass(style.popupdisplay);
                setTimeout(() => {
                    setIsCopiedClass(style.popup);
                }, 500)
            })
            .catch((e) => {
                console.warn('Clipboard error:' + e)
            })
    }

    return (
        <ul className={style.root}>
            <li className={style.listtitle}>Informations diverses :</li>
            <li
                className={style.emailcopy}
                onMouseDown={copyOperation}
                title='Copier'
            >
                renauldyohann@gmail.com&nbsp;
                <Icon
                    name='copy'
                    size='13'
                />
                <div className={isCopiedClass}>Copié !</div>
            </li>
            <li className={style.listitem}>
                <RedirectLink toValue='/files/cv.pdf' targetBlank='true'>
                    <div>
                        Mon CV&nbsp;
                        <Icon
                            name='open-tab'
                            size='13'
                        />
                    </div>
                </RedirectLink>
            </li>
        </ul>
    );
}

export default InformationList;
