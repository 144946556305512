export default {
    "arrow-up" : {
        "src": "/arrows/Up.svg",
        "alt": "arrow up icon"
    },
    "arrow-down" : {
        "src": "/arrows/Down.svg",
        "alt": "arrow down icon"
    },
    "arrow-right" : {
        "src": "/arrows/Right.svg",
        "alt": "arrow right icon"
    },
    "arrow-left" : {
        "src": "/arrows/Left.svg",
        "alt": "arrow left icon"
    },
    "hamburger" : {
        "src": "/Hamburger.svg",
        "alt": "hamburger icon"
    },
    "close" : {
        "src": "/Close.svg",
        "alt": "close icon"
    },
    "open-tab" : {
        "src": "/OpenTab.svg",
        "alt": "open-tab icon"
    },
    "copy" : {
        "src": "/Copy.svg",
        "alt": "copy icon"
    },
    "calendar" : {
        "src": "/Calendar.svg",
        "alt": "calendar icon"
    },
    "cube" : {
        "src": "/Cube.svg",
        "alt": "cube icon"
    },
    "javascript" : {
        "src": "/JavaScript.svg",
        "alt": "javascript icon"
    },
    "php" : {
        "src": "/PHP.svg",
        "alt": "php icon"
    },
    "css" : {
        "src": "/CSS.svg",
        "alt": "css icon"
    },
    "adobe" : {
        "src": "/Adobe.svg",
        "alt": "adobe icon"
    }
};