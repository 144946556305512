import React from 'react';

import SkillItem from './skillItem';

import Title from '../Title/';
import Icon from '../Icon/';

import style from './skills.module.css';

const Skills = () => {
    const skillsData = [
        {
            "id": 0,
            "name": "JavaScript",
            "icon": "javascript",
            "subSkills": [
                {
                    "id": 0,
                    "name": "ReactJS",
                },
                {
                    "id": 1,
                    "name": "VueJS",
                },
                {
                    "id": 2,
                    "name": "PWA",
                },
            ]
        },
        {
            "id": 1,
            "name": "PHP",
            "icon": "php",
            "subSkills": [
                {
                    "id": 0,
                    "name": "Laravel",
                },
                {
                    "id": 1,
                    "name": "Prestashop",
                },
                {
                    "id": 2,
                    "name": "Magento 2",
                },
            ]
        },
        {
            "id": 2,
            "name": "CSS",
            "icon": "css",
            "subSkills": [
                {
                    "id": 0,
                    "name": "Bootstrap",
                },
                {
                    "id": 1,
                    "name": "Tailwind",
                }
            ]
        },
        {
            "id": 3,
            "name": "Adobe",
            "icon": "adobe",
            "subSkills": [
                {
                    "id": 0,
                    "name": "After effect",
                },
                {
                    "id": 1,
                    "name": "Illustrator",
                },
                {
                    "id": 2,
                    "name": "Photoshop",
                },
                {
                    "id": 3,
                    "name": "Premiere pro",
                }
            ]
        },
        {
            "id": 4,
            "name": "Divers",
            "icon": "cube",
            "subSkills": [
                {
                    "id": 0,
                    "name": "Linux",
                },
                {
                    "id": 1,
                    "name": "Git",
                },
                {
                    "id": 2,
                    "name": "Google drive",
                },
                {
                    "id": 3,
                    "name": "Relation client",
                },
            ]
        },
    ];

    const skillsList = skillsData.map((skill) => {
        return (
            <li key={skill.id} className={style.skillItem}>
                <SkillItem skill={skill} open={false} />
            </li>
        );
    })
    
    return (
        <div className={style.root}>
            <Title size='2' sticky='false'>
                Compétences
            </Title>
            <ul className={style.list}>
                {skillsList}
            </ul>
        </div>
    );
}

export default Skills;






