import React from 'react';

import Title from '../Title/';
import Icon from '../Icon/';

import style from './timeline.module.css';

const Timeline = () => {
    
    return (
        <div className={style.root}>
            <Title size='2' sticky='false'>
                Parcours
            </Title>
            <ul className={style.timeline}>
                <li className={style.timelineitem}>
                    <div className={style.itemdate}>
                        <Icon name='calendar' size='13' />&nbsp;2024 - 2026
                    </div>
                    <div>
                        <p className={style.itemtitle}>ESGI Grenoble - Alternance chez vous ?</p>
                        <p className={style.itemlocalisation}>Mastère - Ingénierie du web</p>
                    </div>
                </li>
                <li className={style.timelineitem}>
                    <div className={style.itemdate}>
                        <Icon name='calendar' size='13' />&nbsp;2021 - 2024
                    </div>
                    <div>
                        <p className={style.itemtitle}>IUT1 Grenoble - Alternance chez Evolutis</p>
                        <p className={style.itemlocalisation}>BUT MMI - Parcours développement web</p>
                    </div>
                </li>
                <li className={style.timelineitem}>
                    <div className={style.itemdate}>
                        <Icon name='calendar' size='13' />&nbsp;2018 - 2021
                    </div>
                    <div>
                        <p className={style.itemtitle}>Lycée les Catalins</p>
                        <p className={style.itemlocalisation}>BAC STI2D - SIN (mention bien)</p>
                    </div>
                </li>
                
            </ul>
        </div>
    );
}

export default Timeline;
