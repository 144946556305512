import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../Icon';

import style from './headerMobileNav.module.css';

const HeaderMobileNav = () => {
    const [buttonIcon, setButtonIcon] = useState(<Icon name='hamburger' size='24'/>);
    const [openClass, setOpenClass] = useState(style.closenav);
    const [isOpen, setIsOpen] = useState(false);

    const closeMobileNav = () => {
        setButtonIcon(<Icon name='hamburger' size='24'/>);
        setIsOpen(false);
        setOpenClass(style.closenav);
    }

    const openMobileNav = () => {
        setButtonIcon(<Icon name='close' size='24'/>);
        setIsOpen(true);
        setOpenClass(style.opennav);
    }

    const triggerMobileNavButton = () => {
        if (isOpen) {
            closeMobileNav();
        } else {
            openMobileNav();
        }
    }

    window.addEventListener('scroll', closeMobileNav);

    return (
        <div className={style.root}>
            <button
                className={style.button}
                onClick={triggerMobileNavButton}
            >
                {buttonIcon}
            </button>
            <div className={openClass}>
                <ul className={style.menu}>
                    <li className={style.menuitem}>
                        <Link
                            to='about-me'
                            className={style.link}
                            onClick={closeMobileNav}
                        >
                            Et moi ?
                        </Link>
                    </li>
                    <li className={style.menuitem}>
                        <Link
                            to='/'
                            className={style.link}
                            onClick={closeMobileNav}
                        >
                            Catégories
                        </Link>
                    </li>
                </ul>
                <div
                    className={style.closenavtrigger}
                    onClick={closeMobileNav}
                ></div>
            </div>
        </div>
    );
}

export default HeaderMobileNav;
