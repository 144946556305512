import React, { Fragment, useEffect, useState } from 'react';
import { getTypeName } from './useCategory';

import Title from '../Title';
import WebDevItem from './webDevItem';
import AudiovisualItem from './audiovisualItem';

import style from './listItems.module.css';

const ListItems = props => {
    const { idCategory, projectList, typeList } = props;

    const projectListArray = Object.entries(projectList);
    const projectPath = "https://project.yohannimation.fr/";
    var mediaContent;

    const list = projectListArray.map((projectValue) => {
        let project = projectValue[1];
        var projectName = project.project_name;
        var projectImg = projectPath + project.project_id + "/" + project.project_image;

        const projectTypeName = typeList.find((typeValue) => typeValue.type_id == project.type_id);

        if (idCategory == 1) {
            var projectSrc = project.project_src;
            mediaContent = <WebDevItem projectName={projectName} projectLink={projectSrc} projectImage={projectImg} />;
        } else if (idCategory == 2) {
            var projectSrc = projectPath + project.project_id + "/" + project.project_src;
            mediaContent = <AudiovisualItem  projectSrc={projectSrc} projectPoster={projectImg}  />;
        } else {
            mediaContent = null;
        }

        return (
            <li className={style.root} key={project.project_id}>
                <div className={style.title}>
                    <Title size='2' sticky='false'>
                        {project.project_name}<span className={style.date}> - {project.project_date}</span>
                    </Title>
                </div>
                <div className={style.itemContent}>
                        <div className={style.information}>
                            <p>Projet réalisé dans le cadre {projectTypeName.type_name}</p>
                            <p className={style.description}>
                                <span className={style.underline}>Description&nbsp;:</span>
                                <span>
                                    <span className={style.text} dangerouslySetInnerHTML={{__html: project.project_description}}>
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div className={style.media}>
                            {mediaContent}
                        </div>
                    </div>
            </li>
        );
    })

    return (
        <Fragment>
            {list}
        </Fragment>
    );
}

export default ListItems;
