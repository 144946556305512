import React, { useState, useEffect } from 'react';

import Icon from '../Icon';

import style from './title.module.css'

const Title = props => {
    const { children, size, sticky, test } = props;
    const [buttonUpClass, setButtonUpClass] = useState(style.buttonuptitle);
    
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setButtonUpClass(style.buttonuptitleactive);
            } else {
                setButtonUpClass(style.buttonuptitle);
            }
        })
    }, [])
    
    var title;
    switch (size) {
        case '1' :
            title = <h1>{children}</h1>;
            break;
        case '2' :
            title = <h2>{children}</h2>;
            break;
        case '3' :
            title = <h3>{children}</h3>;
            break;
    }

    const windowToTop = () => {
        window.scrollTo(0,0);
    }

    var buttonUp;
    if (sticky === 'true') {
        buttonUp = (
            <div className={buttonUpClass} onClick={windowToTop}>
                <Icon
                    name='arrow-up'
                    size='48'
                />
            </div>
        );
    } else {
        buttonUp = null;
    }

    return (
        <div className={sticky === 'true' ? style.sticky : style.root} onClick={test}>
            {title}
            {buttonUp}
        </div>
    );
}

export default Title;
