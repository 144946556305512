import React from 'react';

import style from './logo.module.css';

const Logo = () => {

    return (
        <div className={style.root}></div>
    );

}

export default Logo;
