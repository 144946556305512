import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { getCategory, getProjects, getTypeName } from './useCategory';

import LoaderModal from '../LoaderModal';
import Title from '../Title';
import Icon from '../Icon';
import ListItems from './listItems';

import style from './category.module.css';
import ErrorModal from "../ErrorModal";

const Category = () => {
    const { id } = useParams();

    const [error, setError] = useState({"status": false, "message": ""});

    const [fetchedDataCategory, setFetchedDataCategory] = useState({});
    const [dataCategory, setDataCategory] = useState({"name": "","description": ""});
    const [catagoryDataDone, setCatagoryDataDone] = useState(false);

    const [fetchedDataProject, setFetchedDataProject] = useState({});
    const [dataListProject, setDataListProject] = useState({});
    const [projectDataDone, setProjectDataDone] = useState(false);
    const [remainingProject, setRemainingProject] = useState(0);

    const [fetchedDataType, setFetchedDataType] = useState({});
    const [dataListType, setDataListType] = useState({});
    const [typeDataDone, setTypeDataDone] = useState(false);
    
    const [page, setPage] = useState(1);

    const navigate = useNavigate();

    var classInstaPromotion,
        classMoreButton;

    // Category traitment
    const categoryCheckData = () => {
        if (
            fetchedDataCategory
            && fetchedDataCategory.status
        ) {
            if (!catagoryDataDone) {
                const data = fetchedDataCategory.data[0];

                // if category is not enabled. Redirect to home
                if (!data.category_enable) {
                    console.log(fetchedDataCategory.msg);
                    navigate('/', { replace: true });
                }

                // if fetched category id egal to category id of the url page
                if (data.category_id == id) {
                    setDataCategory({
                        "name": data.category_name,
                        "description": data.category_description
                    });
                } else {
                    console.error("the category id in fetched data not corresponding with the current category page");
                }

                // set category data loaded
                setCatagoryDataDone(true);
            }
        }
    }

    // Projects traitment
    let projectList;
    const projectCheckData = () => {
        if (
            fetchedDataProject
            && fetchedDataProject.status
        ) {
            if (!projectDataDone) {
                // mix loaded data with new data fetched
                setDataListProject(prevDataListProject => {
                        const mergedData = new Map(Object.entries(prevDataListProject));
                    
                        for (const [key, value] of Object.entries(fetchedDataProject.data)) {
                            if (mergedData.has(key)) {
                                mergedData.set(key + 1, value);
                            } else {
                                mergedData.set(key, value);
                            }
                        }
                        return Object.fromEntries(mergedData);
                    }
                );

                // change remaining project to display
                setRemainingProject(fetchedDataProject.projectQuantity - (5 * page));

                // set project data loaded
                setProjectDataDone(true);
            }
        } else {
            projectList = '';
        }
    }

    // Type traitment
    const typeCheckData = () => {
        if (
            fetchedDataType
            && fetchedDataType.status
        ) {
            if (!typeDataDone) {
                setDataListType(fetchedDataType.data);

                // set type data loaded
                setTypeDataDone(true);
            }
        }
    }

    // fetch category
    useEffect(() => {
        async function fetchCategory() {
            var categoryResponse;

            try {
                categoryResponse = await getCategory(id, page);
            } catch (error) {
                console.log(error);
            }
            
            if (categoryResponse) {
                setFetchedDataCategory(categoryResponse);
            }
        }
        fetchCategory();
    }, [])

    // fetch projects
    useEffect(() => {
        async function fetchProjects() {
            var projectsResponse;

            try {
                projectsResponse = await getProjects(id, page);
            } catch (error) {
                console.log(error);
                setError({"status": true, "message": error})
                setProjectDataDone(true);
            }
            
            if (projectsResponse) {
                setProjectDataDone(false);
                setFetchedDataProject(projectsResponse);
            }
        }
        

        fetchProjects();
    }, [page])

    // fetch type
    useEffect(() => {
        async function fetchTypes() {
            var typesResponse;

            try {
                typesResponse = await getTypeName();
            } catch (error) {
                console.log(error);
                setError({"status": true, "message": error})
                setTypeDataDone(true);
            }
            
            if (typesResponse) {
                setTypeDataDone(false);
                setFetchedDataType(typesResponse);
            }
        }
        fetchTypes();
    }, [])

    categoryCheckData();
    projectCheckData();
    typeCheckData();

    if (id == 2) {
        classInstaPromotion = style.content;
    } else {
        classInstaPromotion = style.hidden;
    }

    if (remainingProject >= 1) {
        classMoreButton = style.buttonroot;
    } else {
        classMoreButton = style.hidden;
    }

    var ogTitle = "Yohannimation - " + dataCategory.name;
    var ogUrl = "https://yohannimation.fr/category/" + id;

    const loadMore = () => {
        setPage(page + 1);
    }

    return (
        <div className={style.root}>
            <Helmet>
                <meta name='og:title' content={ogTitle} />
                <meta name='og:site_name' content={ogTitle} />
                <title>{ogTitle}</title>
                <meta name='og:description' content={dataCategory.description} />
                <meta name='description' content={dataCategory.description} />
                <meta name='category' content={dataCategory.name} />
                <meta name='og:url' content={ogUrl} />
                <link rel="canonical" href={ogUrl} />
                <meta property="og:type" content="website" />
            </Helmet>

            <LoaderModal done={catagoryDataDone && projectDataDone && typeDataDone} />
            {error.status ? <ErrorModal message={error.message} /> : ''}
            <Title size='1' sticky='true'>
                {dataCategory.name}
            </Title>
            <p className={style.description}>{dataCategory.description}</p>
            <article className={style.content}>
                <ul className={style.listcontent} >
                    {projectDataDone && typeDataDone ? <ListItems idCategory={id} projectList={dataListProject} typeList={dataListType} /> : ''}
                </ul>
                <button className={classMoreButton} onClick={loadMore}>
                    <div className={style.button}>Voir plus</div>
                    <div className={style.bg}></div>
                </button>
            </article>
            <article className={classInstaPromotion}>
                <p>
                    Mon compte Instagram <a href="https://www.instagram.com/yohannimation/" className={style.link} target="_blank">yohannimation&nbsp;<Icon name='open-tab' size='13' /></a> regroupe tous mes projets audiovisuel.
                </p>
            </article>
        </div>
    );
}

export default Category;