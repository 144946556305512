import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

import style from './image.module.css';

const Image = props => {
    const {src, alt, className, lazy} = props;

    if (lazy == "true") {
        return (
            <LazyLoadImage
                src={src}
                width="100%"
                height="100%"
                alt={alt}
                effect='blur'
                className={className}
            />
        );
    } else {
        return <img src={src} className={className} alt={alt} />;
    }
    
}

export default Image;