import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function QrCode() {
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAnalytics() {
      if (sessionStorage.getItem('session') === null || Date.now() >= sessionStorage.getItem('session')) {
        var timestamp = Date.now();
        let date = new Date(timestamp);
        date.setMinutes(date.getMinutes() + 30);
        timestamp = date.getTime();
    
        sessionStorage.setItem('session', timestamp);
    
        const body = new FormData();
        body.append('action-type', 'analytics');
        body.append('analytics-type', 'qrcode');
        try {
            const response = await fetch("https://api.yohannimation.fr/API.php", {
                method: "POST",
                body: body
            })
    
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const jsonResponse = await response.json();
            if (!jsonResponse.status) {
                throw new Error(jsonResponse.msg);
            } else {
            }
        } catch (error) {
        }
      }
    }
    fetchAnalytics();
  }, [])

  


  setTimeout(() => {
    navigate('/', { replace: true });
  }, 10)

  return (
      <Fragment></Fragment>
  );
}

export default QrCode;
