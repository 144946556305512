import React, { useState, useEffect } from 'react';

import Title from '../Title/';
import Icon from '../Icon/';

import style from './skillItem.module.css';

const Skills = props => {
    const { skill, open } = props;
    const [skillIsOpen, setSkillIsOpen] = useState(open);
    const [skillOpen, setSkillOpen] = useState();

    const name = skill.name;
    const icon = skill.icon;
    const subSkills = skill.subSkills

    const subSkillsList = subSkills.map((subSkill) => {
        return (
            <li key={subSkill.id} className={style.item}>
                {subSkill.name}
            </li>
        );
    })
    
    useEffect(() => {
        if (skillIsOpen) {
            setSkillOpen(style.skillOpen);
        } else {
            setSkillOpen(style.skillClose);
        }
    }, [open])

    const handleOpen = () => {
        if (!skillIsOpen) {
            setSkillOpen(style.skillOpen);
        } else {
            setSkillOpen(style.skillClose);
        }
        setSkillIsOpen(!skillIsOpen);
    }

    return (
        <div className={skillOpen} onClick={handleOpen}>
            <div className={style.content}>
                <div className={style.titleContainer}>
                    <div className={style.title}>
                        <Icon name={icon} size='24' />
                        <p>{name}</p>
                    </div>
                    <div className={style.arrow}>
                        <Icon name='arrow-down' size='24' />
                    </div>
                </div>
                <ul className={style.list}>
                    {subSkillsList}
                </ul>
            </div>
        </div>
    );
}

export default Skills;






