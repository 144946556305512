import React from 'react';

import icons from './iconsData.js';

import style from './icon.module.css';

const Icon = props => {
    const { name, size } = props;
    var classNameValue = 'small-icon';

    const iconData = icons[name];
    const iconSrc = '/icon' + iconData.src;

    switch (size) {
        case '13':
            classNameValue = style.smallicon;
            break;
        case '24':
            classNameValue = style.mediumicon;
            break;
        case '48':
            classNameValue = style.bigicon;
            break;
        default:
            classNameValue = style.smallicon;
            break;
    }

    return (
        <img src={iconSrc} className={classNameValue} alt={iconData.alt} />
    );
}

export default Icon;
