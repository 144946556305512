import React, { useEffect, useState } from 'react';

import Loader from '../Loader';

import style from './loaderModal.module.css'

const LoaderModal = props => {
    const { done } = props;

    const [modalLoaderClass, setModalLoaderClass] = useState(style.root);

    useEffect(() => {
        if (done) {
            setModalLoaderClass(style.rootinactive)
        }
    }, [done]);

    return (
        <div className={modalLoaderClass}>
            <Loader />
        </div>
    );
}

export default LoaderModal;
