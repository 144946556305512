import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../../pages/Home';
import Category from '../Category';
import AboutMe from '../../pages/AboutMe';
import QrCode from '../QrCode';
import PageNotFound from '../PageNotFound';

function Router() {
  return (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/category/:id" element={<Category />} />
    <Route path="/about-me" element={<AboutMe />} />
    <Route path="/category" element={<PageNotFound />} />
    <Route path="/qrcode" element={<QrCode />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
  );
}

export default Router;
