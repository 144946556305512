import React from 'react';

import RedirectLink from '../RedirectLink';
import Icon from '../Icon'

import './footerList.module.css';
import style from "./footerList.module.css";

const SocialMediaList = () => {
    return (
        <ul className={style.root}>
            <li className={style.listtitle}>Mes réseaux-sociaux :</li>
            <li className={style.listitem}>
                <RedirectLink toValue='https://www.instagram.com/yohannimation/' targetBlank='true'>
                    <div>
                        Instagram&nbsp;
                        <Icon
                            name='open-tab'
                            size='13'
                        />
                    </div>
                </RedirectLink>
            </li>
            <li className={style.listitem}>
                <RedirectLink toValue='https://fr.linkedin.com/in/yohann-renauld' targetBlank='true'>
                    <div>
                        LinkedIn&nbsp;
                        <Icon
                            name='open-tab'
                            size='13'
                        />
                    </div>
                </RedirectLink>
            </li>
        </ul>
    );
}

export default SocialMediaList;
