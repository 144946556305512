import React from 'react';

import Router from '../Router';

import style from './main.module.css';

const Main = () => {

    return (
        <main className={style.root}>
            <Router />
        </main>
    );
}

export default Main;
