export const getCategories = async () => {
    const fetchUrl = "https://api.yohannimation.fr/Getter.php?type=categories";

    try {
        const response = await fetch(fetchUrl);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const jsonResponse = await response.json();
        if (!jsonResponse.status) {
            throw new Error(jsonResponse.msg);
        }
        return jsonResponse;
    } catch (error) {
        throw new Error(error);
    }
}

export const analytics = async () => {
    if (sessionStorage.getItem('session') === null || Date.now() >= sessionStorage.getItem('session')) {
        var timestamp = Date.now();
        let date = new Date(timestamp);
        date.setMinutes(date.getMinutes() + 30);
        timestamp = date.getTime();
    
        sessionStorage.setItem('session', timestamp);

        const body = new FormData();
        body.append('action-type', 'analytics');
        body.append('analytics-type', 'search');
        try {
            const response = await fetch("https://api.yohannimation.fr/API.php", {
                method: "POST",
                body: body
            })

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const jsonResponse = await response.json();
            if (!jsonResponse.status) {
                throw new Error(jsonResponse.msg);
            } else {
            }
        } catch (error) {
        }
    }
}