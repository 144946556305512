import React from 'react';

import style from './loader.module.css'

const Loader = () => {

    const mascotteSrc = "/mascotte/LoadingMascotte.svg";
    const loaderSrc = "/mascotte/Loading.svg";

    return (
        <div className={style.root}>
            <img src={mascotteSrc} className={style.mascotteloader} alt='mascotte-loader' />
            <img src={loaderSrc} className={style.cercle} alt='cercle-loader' />
            <p className={style.text}>Chargement...</p>
        </div>
    );
}

export default Loader;
