import React from 'react';

import RedirectButton from '../RedirectButton';
import RedirectLink from '../RedirectLink';

import style from './dualButton.module.css';

const DualButton = props => {
    const { name1, toValue1, name2, toValue2 } = props;

    return (
        <div className={style.root}>
            <RedirectLink toValue={toValue1}>
                {name1}
            </RedirectLink>
            <RedirectButton name={name2} toValue={toValue2} />
        </div>
    );
}

export default DualButton;
