import React from 'react';
import { Helmet } from "react-helmet";

import Title from '../../components/Title';
import Icon from '../../components/Icon';
import Image from '../../components/Image'
import Timeline from '../../components/AboutMeTimeline';
import Skills from '../../components/AboutMeSkills';
import Passion from '../../components/AboutMePassion';

import style from './aboutMe.module.css';

const AboutMe = () => {
    const imgAboutMePath = process.env.PUBLIC_URL + '/images/aboutme.png';

    const urlMmi = "https://www.onisep.fr/ressources/univers-formation/formations/post-bac/but-metiers-du-multimedia-et-de-l-internet-parcours-creation-numerique";
    const urlEvolutis = "https://www.evolutis.fr/";
    const urlEsgi = "https://www.esgi.fr/";
    
    return (
        <div className={style.root}>
        <Helmet>
            <meta name='og:title' content='Yohannimation - À propos' />
            <meta property="og:site_name" content="Yohannimation - À propos" />
            <title>Yohannimation - À propos</title>
            <meta name="og:description" content="Je suis à la recherche d'une entreprise pouvant m'embaucher en tant qu'alternant développeur web full-stack. Venez en découvrir un peu plus sur moi !" />
            <meta name="description" content="Je suis à la recherche d'une entreprise pouvant m'embaucher en tant qu'alternant développeur web full-stack. Venez en découvrir un peu plus sur moi !" />
            <meta name='category' content='À propos' />
            <meta name='og:url' content='https://yohannimation.fr/about-me' />
            <link rel="canonical" href="https://yohannimation.fr/about-me" />
            <meta property="og:type" content="website" />
        </Helmet>

            <Title size='1' sticky='false'>
                À propos
            </Title>
            <article className={style.content}>
                <div className={style.presentation}>
                    <div className={style.text}>
                        <Title size='2' sticky='false'>
                            <div className={style.name}>Yohann RENAULD</div>
                        </Title>
                        <p className={style.description}>
                            Actuellement en 3<sup>ème</sup> année de BUT <a href={urlMmi} className={style.link} target="_blank">MMI&nbsp;<Icon name='open-tab' size='13' /></a> (Métiers du Multimédia et de l'Internet)
                            spécialité "Développement web et dispositifs interactifs" et j'effectue cette formation en alternance chez <a href={urlEvolutis} className={style.link} target="_blank">Evolutis&nbsp;<Icon name='open-tab' size='13' /></a> .<br />
                            Durant les années 2024 à 2026, je vais intégrer un mastère à l'<a href={urlEsgi} className={style.link} target="_blank">ESGI&nbsp;<Icon name='open-tab' size='13' /></a> (École Supérieure de Génie Informatique) dans le parcours "Ingénierie du web".
                        </p>
                        <p className={style.description}>
                            Je suis à la recherche d'une entreprise, me proposant une offre d'alternance en tant que développeur web full-stack.<br />
                            Suite à mes années d'étude, j'espère être embauché au sein de l'entreprise dans laquelle je suis, en tant que développeur web full-stack ou bien front-end.
                        </p>
                        <p className={style.description}>
                            Mon CV et mes informations de contact sont dans le <a href="#footer" className={style.link} >pied de page&nbsp;<Icon name='arrow-down' size='13' /></a> .
                        </p>
                    </div>
                    <div className={style.imgcontainer}>
                        <Image
                            src={imgAboutMePath}
                            alt='An picture of my pretty face'
                            className={style.img}
                            lazy="false"
                        />
                    </div>
                </div>
                <Timeline />
                <Skills />
                <Passion />
            </article>
        </div>
    );
}

export default AboutMe;
