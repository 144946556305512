import React, { useEffect, useState } from 'react';

import Title from '../Title';
import Icon from '../Icon';

import './audiovisualItem.css'

const AudiovisualItem = props => {
    const { projectSrc, projectPoster } = props;

    return (
        <video className='audiovisualitem-root' controls poster={projectPoster}>
            <source src={projectSrc} type='video/mp4' />
        </video>
    );
}

export default AudiovisualItem;
