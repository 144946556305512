import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo/logo';

import style from './errorModal.module.css';

const ErrorModal = props => {
    const { message } = props;
    const mascotteSrc = "/mascotte/BoredMascotte.svg";

    var messageToDisplay;
    switch (message.toString()) {
        case "Error: Error: No project to display":
            messageToDisplay = "Il n'y a pas de projet à charger...";
            break;
        case "Error: TypeError: NetworkError when attempting to fetch resource.":
            messageToDisplay = "Un problème de connexion a eu lieu lors de la requête...";
            break;
        default:
            messageToDisplay = message.toString().replace("Error: ", '');
            break;
    }

    return (
        <div className={style.root}>
            <img src={mascotteSrc} className={style.img} alt='bored mascotte' />
            <p className={style.text}>{messageToDisplay}</p>
        </div>
    );
}

export default ErrorModal;