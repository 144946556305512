import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

import style from './categoriesList.module.css'

const categoriesList = props => {
    const { categoriesData } = props;

    const categoriesList = categoriesData.map((category) => {
        var categoryUrl;
        if (category.category_id === 5) {
            categoryUrl = '/about-me';
        } else {
            categoryUrl = '/category/' + category.category_id;
        }

        return (
            <li className={style.navelement} key={category.category_id}>
                <Link to={categoryUrl} className={style.navlink}>
                    {/*<Icon name={category.iconName} size='24' />*/}
                    <p>{category.category_name}</p>
                    <span className={style.navicon}>
                        <div className={style.arrow}>
                            <span className={style.toparrow}></span>
                            <span className={style.middlearrow}></span>
                            <span className={style.bottomarrow}></span>
                        </div>
                    </span>
                </Link>
            </li>
        );
    })

    return (
        <Fragment>
            {categoriesList}
        </Fragment>
    );
}

export default categoriesList;
