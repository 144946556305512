import React from 'react';
import { Link } from 'react-router-dom';

import HeaderNav from './HeaderNav';
import Logo from '../Logo/logo';

import style from './header.module.css';

const Header = () => {
    return (
        <header className={style.root}>
            <Link to='/'>
                <Logo />
            </Link>
            <HeaderNav />
        </header>
    );
}

export default Header;
