import React from 'react';

import Title from '../Title/';
import Image from '../Image';

import style from './passion.module.css';

const AboutMe = () => {
    const passions = [
        {
            "id": 1,
            "name": "VTT",
            "imgName": "aboutme-mtb.jpg",
            "alt": "image passion mtb",
        },
        {
            "id": 2,
            "name": "Randonnée",
            "imgName": "aboutme-hike.jpg",
            "alt": "image passion hike",
        },
        {
            "id": 3,
            "name": "Photographie",
            "imgName": "aboutme-photo.jpg",
            "alt": "image passion photo",
        },
    ]

    const passionList = passions.map((passion) => {
        const passionImgUrl = process.env.PUBLIC_URL + '/images/' + passion.imgName;
        return (
            <li className={style.passionitem} key={passion.id}>
                <div className={style.imgcontainer}>
                    <Image
                        src={passionImgUrl}
                        alt={passion.alt}
                        className={style.img}
                        lazy="true"
                    />
                </div>
                <p className={style.title}>{passion.name}</p>
            </li>
        );
    })
    
    return (
        <div className={style.root}>
            <Title size='2' sticky='false'>
                Passions
            </Title>
            <ul className={style.passion}>
                {passionList}
            </ul>
        </div>
    );
}

export default AboutMe;
