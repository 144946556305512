import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

import Title from '../Title';
import Icon from '../Icon';
import Image from '../Image';

import './webDevItem.css'

const WebDevItem = props => {
    const { projectName, projectLink, projectImage } = props;

    const projectAlt = "image for project " + projectName;

    return (
        <a href={projectLink} className='webdevitem-root' target='_blank'>
            <div className='image-container'>
                <Image
                    src={projectImage}
                    alt={projectAlt}
                    className='image'
                    lazy="true"
                />
            </div>
            <p className='text'>Voir le site&nbsp;<Icon name='open-tab' size='24' /></p>
        </a>
    );
}

export default WebDevItem;
