import React from 'react';
import { Link } from 'react-router-dom';

import style from './redirectLink.module.css';

const RedirectLink = props => {
    const { children, toValue, targetBlank } = props;

    if (targetBlank) {
        return (
            <Link to={toValue} className={style.root} target="_blank">{children}</Link>
        );
    } else {
        return (
            <Link to={toValue} className={style.root}>{children}</Link>
        );
    }
}

export default RedirectLink;
