import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DualButton from '../../DualButton';
import HeaderMobileNav from './headerMobileNav';

import style from './headerNav.module.css';

const HeaderNav = () => {
    const [headerNavClass, setHeaderNavClass] = useState('headernav-root display');
    const location = useLocation();
    
    useEffect(() => {
        if (location.pathname !== '/') {
            setHeaderNavClass(style.root);
        } else {
            setHeaderNavClass(style.hidden);
        }
    }, [location])

    return (
        <nav className={headerNavClass}>
            <div className={style.desktopnav}>
                <DualButton
                    name1="Et moi ?"
                    toValue1="/about-me"
                    name2="Catégories"
                    toValue2="/"
                />
            </div>
            <div className={style.mobilenav}>
                <HeaderMobileNav />
            </div>
        </nav>
    );
}

export default HeaderNav;